'use client';

import { Container } from '@mui/material';

import OfferIcon from 'libs/components/icons/offer-icon';
import {
  StyledBox,
  StyledOfferContainer,
  StyledOfferText,
  StyledText,
} from './styles';

type Props = {
  planDetails: {
    price: number;
    currency: string;
    symbol: string;
  };
  onClick: () => void;
};

function OfferBanner({ onClick, planDetails }: Props) {
  const text = `Get Unlimited Downloads With a Commercial License Starting at just ${
    planDetails ? `${planDetails?.symbol}${planDetails?.price}` : 'INR ₹249'
  } per month!`;

  return (
    <StyledOfferContainer onClick={onClick}>
      <Container maxWidth="lg">
        <StyledBox>
          <OfferIcon />
          <StyledOfferText>EXCLUSIVE OFFER</StyledOfferText>
          <StyledText>{text}</StyledText>
        </StyledBox>
      </Container>
    </StyledOfferContainer>
  );
}

export default OfferBanner;
