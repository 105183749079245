'use client';
import React from 'react';

import { Button, Stack, Typography, styled } from '@mui/material';

import { FlexBoxJustify } from 'libs/components/Flex/flex-box';
import { DialogDrawer } from 'libs/components/dialog-drawer';

interface FreeuserProps {
	children?: React.ReactNode;
	user?: string;
}

const Freeuser = ({ children, user }: FreeuserProps) => {
  const HorizontalBar = styled('div')(() => ({
    borderBottom: '2px solid black',
    width: '80%',
    margin: '0 auto',
  }));

  return (
    <DialogDrawer
      dialogOpen
      toggleDialog={() => {}}
      wrapperStyle={{ height: '100%', width: '100%' }}
    >
      <Stack
        spacing={2}
        justifyContent={'center'}
        alignItems={'center'}
        padding={'5%'}
      >
        <Typography
          color="#404040"
          textAlign="center"
          fontSize={'1rem'}
          fontStyle={'normal'}
          fontWeight={500}
          lineHeight={'normal'}
        >
					Become a premium member and get access to Exclusive Content
        </Typography>
        <Stack width={'100%'}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            sx={{
              borderRadius: '0.25rem',
            }}
          >
            {user === 'Pro' ? 'Pay $59' : 'Subscribe & Download'}
          </Button>
          <Typography
            color="#7B7B7B"
            textAlign="center"
            fontSize="0.625rem"
            font-style="normal"
            font-weight={400}
            line-height="normal"
          >
            {user === 'Pro'
              ? 'pay $59 to upgrade. Learn more'
              : 'Starts at $12.99 per month Learn more'}
          </Typography>
        </Stack>
        <FlexBoxJustify alignItems={'center'} width={'100%'}>
          <HorizontalBar />

          <Typography padding={'1%'}>OR</Typography>

          <HorizontalBar />
        </FlexBoxJustify>

        <Typography
          color={'#404040'}
          fontSize={'0.875rem'}
          font-style={'normal'}
          font-weight={500}
          line-height={'normal'}
        >
					Online Earning Concept Flat Design Vector Animation Scene
        </Typography>
        {children}
      </Stack>
    </DialogDrawer>
  );
};

export default Freeuser;
