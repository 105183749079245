import { Fragment } from 'react';

import Link from 'next/link';

import { Stack, Typography } from '@mui/material';

import useAuth from 'hooks/useAuth';
import { SubItems } from './style';

const category = [
  {
    name: 'Help center',
    href: '/contact',
  },
  {
    name: 'Become an affiliate',
    href: '/affiliate',
  },
  {
    name: 'Become an author',
    href: '/sell-your-content',
  },
  {
    name: 'Licence',
    href: '/licence',
  },
];

const Links = () => {
  const { state } = useAuth();

  return (
    <Stack alignSelf="start" spacing={1}>
      <Typography variant="h6" fontWeight={600}>
        Helpful Links
      </Typography>
      {category.map((item, index) => {
        item.href =
          item.name === 'Become an author' &&
          state?.user?.role?.toLowerCase() === 'author'
            ? '/author'
            : item.href;

        item.name =
          item.name === 'Become an author' &&
          state?.user?.role?.toLowerCase() === 'author'
            ? 'View DashBoard'
            : item.name;

        return (
          <Fragment key={index}>
            <Link href={item.href}>
              <SubItems fontSize={'0.87rem'} fontWeight={400}>
                {item.name}
              </SubItems>
            </Link>
          </Fragment>
        );
      })}
    </Stack>
  );
};

export default Links;
