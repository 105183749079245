import Image from 'next/image';

import { Box, Button, Stack, Typography } from '@mui/material';

import { DialogDrawer } from 'libs/components/dialog-drawer';


const PremiumSubscription = () => {
  return (
    <DialogDrawer
      dialogOpen
      toggleDialog={() => { }}
      wrapperStyle={{ height: '100%', width: '100%' }}

    >
      <Stack justifyContent={'center'} spacing={2} alignItems={'center'} padding={'10%'} minWidth={{ md: '30vw', sm: '50vw', xs: '100%' }}>
        <Box>
          <Typography
            color='#6631FB'
            textAlign={'center'}
            fontSize='1rem'
            fontStyle='normal'
            fontWeight={500}
            lineHeight='normal'

          >
                        Gratitude in every byte! Thanks for subscribing and being a vital part of our community.
          </Typography>
        </Box>
        <Box >
          <Image src={'assets/images/popup-images/Group 1000005263.svg'} alt="Gratitude"
            height={200}
            width={300}
            style={{
              display: 'block',
              maxWidth: '100%',
            }}
          />

        </Box>
        <Typography color={'#404040'}
          fontSize={'0.875rem'}
          font-style={'normal'}
          font-weight={500}
          line-height={'normal'}>Online Earning Concept Flat Design Vector Animation Scene</Typography>
        <Box width={'100%'}>
          <Button variant="contained" color="primary" fullWidth>
                        Download
          </Button>
        </Box>
      </Stack>
    </DialogDrawer>
  );
};

export default PremiumSubscription;
