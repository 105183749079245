

import createSvgIcon from '@mui/material/utils/createSvgIcon';

const OfferIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M10.8 14.4003V24H6C5.04522 24 4.12955 23.6207 3.45442 22.9456C2.77928 22.2705 2.4 21.3549 2.4 20.4001V15.6002C2.4 15.282 2.52643 14.9768 2.75147 14.7517C2.97652 14.5267 3.28174 14.4003 3.6 14.4003H10.8ZM20.4 14.4003C20.7183 14.4003 21.0235 14.5267 21.2485 14.7517C21.4736 14.9768 21.6 15.282 21.6 15.6002V20.4001C21.6 21.3549 21.2207 22.2705 20.5456 22.9456C19.8705 23.6207 18.9548 24 18 24H13.2V14.4003H20.4ZM17.4 0.000704253C18.1086 0.000558004 18.8057 0.179699 19.4265 0.52145C20.0473 0.863202 20.5714 1.35645 20.9503 1.95529C21.3291 2.55412 21.5503 3.23906 21.5932 3.94636C21.6361 4.65366 21.4993 5.36031 21.1956 6.00053H21.6C22.2365 6.00053 22.847 6.25338 23.2971 6.70345C23.7471 7.15353 24 7.76396 24 8.40046V9.60042C24 10.2369 23.7471 10.8474 23.2971 11.2974C22.847 11.7475 22.2365 12.0004 21.6 12.0004H13.2V6.00053H10.8V12.0004H2.4C1.76348 12.0004 1.15303 11.7475 0.702944 11.2974C0.252856 10.8474 0 10.2369 0 9.60042V8.40046C0 7.76396 0.252856 7.15353 0.702944 6.70345C1.15303 6.25338 1.76348 6.00053 2.4 6.00053H2.8044C2.53786 5.43793 2.39973 4.82312 2.4 4.20058C2.4 1.88105 4.2804 0.000704253 6.5796 0.000704253C8.6856 -0.0352947 10.554 1.31107 11.8368 3.5214L12 3.81299C13.2396 1.51626 15.072 0.0763021 17.1492 0.00310429L17.4 0.000704253ZM6.6 2.40063C6.12261 2.40063 5.66477 2.59027 5.32721 2.92783C4.98964 3.26538 4.8 3.72321 4.8 4.20058C4.8 4.67796 4.98964 5.13578 5.32721 5.47334C5.66477 5.81089 6.12261 6.00053 6.6 6.00053H10.3716C9.4824 3.7146 8.0328 2.37663 6.6 2.40063ZM17.3796 2.40063C15.9636 2.37663 14.5176 3.7158 13.6284 6.00053H17.4C17.8774 5.99782 18.3342 5.80559 18.6698 5.46612C19.0055 5.12666 19.1925 4.66776 19.1898 4.19038C19.1871 3.71301 18.9949 3.25626 18.6554 2.92061C18.3159 2.58497 17.857 2.39793 17.3796 2.40063Z" fill="white"/>
  </svg>,
  'OfferIcon'
);

export default OfferIcon;
