'use client';
import React, { useState } from 'react';

import Cookies from 'js-cookie';

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  styled,
} from '@mui/material';

import { requestor } from 'libs/helpers/api/requestor';
import useDialougeToggler from 'hooks/useDialougeToggler';
import { FlexBoxCentered } from '../Flex/flex-box';

export const StyledTextField = styled(TextField)(() => ({
  borderRight: '1px solid',

  borderBottom: '1px solid',
  outline: 'none',
  border: 'none',
  variant: 'filled',
}));
export const StyledCountryCodeSelect = styled('select')(() => ({
  alignItems: 'center',
  padding: '3%',
  outline: 'none',
  borderColor: '#bdbfc2',
  borderRight: 'none',
  height: '100%',
  backgroundColor: '#f6f9fc',
}));

const Registration = () => {
  const { dispatch } = useDialougeToggler();
  const [validationErors, setValidationErrors] = useState({
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    passwordError: false,
  });

  const [emailMessageError, setEmailMessageError] = useState(
    'Invalid Email Message!...'
  );

  const [userInput, setUserInput] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    voucherCode: '',
    phoneNumber: '',
    countryCode: '',
  });

  const countryCodes = [
    { label: '+1' }, // United States
    { label: '+91' }, // India
    { label: '+93' }, // Afghanistan
    { label: '+355' }, // Albania
    { label: '+213' }, // Algeria
    { label: '+376' }, // Andorra
    { label: '+244' }, // Angola
    { label: '+672' }, // Antarctica
    { label: '+54' }, // Argentina
    { label: '+374' }, // Armenia
    { label: '+297' }, // Aruba
    { label: '+61' }, // Australia
    { label: '+43' }, // Austria
    { label: '+994' }, // Azerbaijan
  ];

  const onChangeHandler = (
    e:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLSelectElement>
			| any
  ) => {
    setUserInput({
      ...userInput,
      [e.target.name]: e.target.value,
    });
    const nameRegex = /^[A-Za-z'-]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

    setValidationErrors({
      ...validationErors,
      firstNameError: nameRegex.test(userInput.firstName),
      lastNameError: nameRegex.test(userInput.lastName),
      emailError: emailRegex.test(userInput.email),

      passwordError: passwordRegex.test(userInput.password),
    });
  };

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const result: any = await requestor.post(`${process.env.API_BASE_URL}/`, {
        ['first_name']: userInput.firstName,
        ['last_name']: userInput.lastName,
        email: userInput.email,
        username: userInput.email,
        password: userInput.password,
        ['phone_number']: Number(userInput.phoneNumber),
      });
      const { transactionId }: { transactionId: string } = result.data;

      sessionStorage.setItem(
        'verificationCredentials',
        JSON.stringify({ transactionId: transactionId, email: userInput.email })
      );

      if (result?.success) {
        Cookies.set('token', result.data);
        dispatch({
          type: 'TOGGLE',
          payload: {
            toggled: true,
            element: 'VerificationForm',
            closeBtn: false,
          },
        });
      }
    } catch (error: any) {
      if (error.response.data.message) {
        setValidationErrors({
          ...validationErors,
          emailError: false,
        });
        setEmailMessageError('Email Already Exists!');
      }
    }
  };

  const StyledOption = styled('option')({
    padding: '8px',
    fontSize: '16px',
    backgroundColor: '#fff',
    color: '#333',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  });

  return (
    <form
      name="registration"
      onSubmit={submitHandler}
      style={{ display: 'grid', placeContent: 'center' }}
    >
      <Grid
        container
        spacing={2}
        width={{ md: '22rem', sm: '15rem', xs: '22rem' }}
        justifyContent={'center'}
      >
        <Grid item xs={6}>
          <TextField
            label="First Name"
            fullWidth
            variant="outlined"
            required
            name="firstName"
            onChange={onChangeHandler}
            value={userInput.firstName}
            helperText={
              userInput.firstName !== '' &&
							!validationErors.firstNameError && (
                <span>Invalid First Name⚠️</span>
              )
            }
            color={validationErors.firstNameError ? 'success' : 'error'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Last Name"
            fullWidth
            variant="outlined"
            required={true}
            name="lastName"
            value={userInput.lastName}
            onChange={onChangeHandler}
            helperText={
              userInput.lastName !== '' &&
							!validationErors.lastNameError && <span>Invalid Lastname⚠️</span>
            }
            color={validationErors.lastNameError ? 'success' : 'error'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            required={true}
            name="email"
            value={userInput.email}
            onChange={onChangeHandler}
            helperText={
              userInput.email !== '' &&
							!validationErors.emailError && <span>{emailMessageError}⚠️</span>
            }
            color={validationErors.emailError ? 'success' : 'error'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Password"
            type="password"
            fullWidth
            variant="outlined"
            required={true}
            name="password"
            value={userInput.password}
            onChange={onChangeHandler}
            helperText={
              userInput.password !== '' &&
							!validationErors.passwordError && (
                <span>
									Password should contain a minimum length of 8 characters, at
									least one digit, and at least one alphabetical character⚠️
                </span>
              )
            }
            color={validationErors.passwordError ? 'success' : 'error'}
          />
        </Grid>
        <Grid item xs={12}>
          <FlexBoxCentered>
            <StyledCountryCodeSelect
              onChange={onChangeHandler}
              value={userInput.countryCode}
              name="countryCode"
            >
              {countryCodes.map((countryCode) => (
                <StyledOption value={countryCode.label} key={countryCode.label}>
                  {countryCode.label}
                </StyledOption>
              ))}
            </StyledCountryCodeSelect>
            <StyledTextField
              label="Phone Number [optional]"
              fullWidth
              name="phoneNumber"
              value={userInput.phoneNumber}
              onChange={onChangeHandler}
              inputProps={{
                maxLength: 10,
                minLength: 10,
              }}
            />
          </FlexBoxCentered>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Referral [optional]"
            fullWidth
            variant="outlined"
            name="voucherCode"
            value={userInput.voucherCode}
            onChange={onChangeHandler}
            color="success"
            sx={{
              display: 'none',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox required onChange={onChangeHandler} />}
            label={
              <span style={{ fontSize: '0.75rem', color: '#6E5BE4' }}>
								I agree with DesignTemplate.io’s User Agreement and Privacy
								policy
              </span>
            }
            color="#6E5BE4"
            sx={{ fontSize: '0.75rem' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            size={'large'}
            fullWidth
            color="primary"
            type="submit"
            disabled={
              !validationErors.firstNameError ||
							!validationErors.lastNameError ||
							!validationErors.emailError ||
							!validationErors.passwordError
            }
          >
						Create Account
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Registration;
