'use client';
import React from 'react';

import { ToastContainer } from 'react-toastify';

import { useParams, useRouter } from 'next/navigation';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { useLogin } from 'hooks/useLogin';
import { Footer } from 'libs/components/footer';
import { HeaderNav } from 'libs/components/header-with-nav';
import { MobileNavigationBar2 } from 'libs/components/mobile-navigation';
import OfferBanner from 'libs/components/offer';
import 'react-toastify/dist/ReactToastify.css';
import { TopBar } from '../user-dashboard/styles';

export default function HomeLayout({
  children,
  planDetails,
}: {
  children: React.ReactNode;
  planDetails: {
    price: number;
    currency: string;
    symbol: string;
  };
}) {
  useLogin();
  const params = useParams();
  const router = useRouter();

  return (
    <Box position={'relative'}>
      <TopBar elevation={2}>
        <Container maxWidth="lg">
          <HeaderNav />
        </Container>
      </TopBar>
      {params.template !== 'pricing' &&
        params.template !== 'paymentCheckout' && (
          <OfferBanner
            onClick={() => {
              if (window && window?.clarity) {
                window.clarity('set', 'offer_banner_clicked', 'confirmed');
              }

              router.push('/pricing');
            }}
            planDetails={planDetails}
          />
        )}
      {children}
      <MobileNavigationBar2 />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="colored"
        style={{ transition: 'ease-out' }}
      />

      <Footer />
    </Box>
  );
}
