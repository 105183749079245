'use client';
import { FC } from 'react';

import AppBar from '@mui/material/AppBar/AppBar';
import Card from '@mui/material/Card';
import styled from '@mui/material/styles/styled';

import NavLink, { NavLinkProps } from 'libs/components/nav-link/nav-link';

export const MainContainer = styled(Card)(({ theme }) => ({
  paddingBottom: '1.5rem',
  [theme.breakpoints.down('md')]: {
    boxShadow: 'none',
    overflowY: 'auto',
    height: 'calc(100vh - 6px)',
  },
}));

type Props = { isCurrentPath: boolean };

export const StyledNavLink = styled<FC<Props & NavLinkProps>>(
  ({ children, ...rest }) => (
    <NavLink {...rest}>{children}</NavLink>
  ),
  { shouldForwardProp: (prop) => prop !== 'isCurrentPath' }
)<Props>(({ theme, isCurrentPath }) => ({
  display: 'flex',
  alignItems: 'center',
  borderLeft: '4px solid',
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  marginBottom: '1.25rem',
  justifyContent: 'space-between',
  borderColor: isCurrentPath ? theme.palette.primary.main : 'transparent',
  '& .nav-icon': {
    color: isCurrentPath ? theme.palette.primary.main : theme.palette.primary.main,
  },
  '&:hover': {
    borderColor: theme.palette.primary.main,
    '& .nav-icon': { color: theme.palette.primary.main },
  },
}));

export const NavigationLink = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderLeft: '4px solid',
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  marginBottom: '1.25rem',
  justifyContent: 'space-between',
  color: 'grey.600',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    '& .nav-icon': { color: theme.palette.primary.main },
  },
  '&:active': {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '& .nav-icon': { color: theme.palette.primary.main },
  },
}));

export const TopBar = styled(AppBar)({
  width: '100%',
  height: '7rem',
  backgroundColor: '#FFFDFC',
  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)',
  position: 'sticky',
  '@media (max-width: 368px)': {
    height: '4rem',
  },
  '@media (min-width: 368px) and (max-width: 600px)': {
    height:'4rem'
  },
});
