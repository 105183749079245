import { Fragment } from 'react';

import Link from 'next/link';

import { Stack, Typography } from '@mui/material';

import { SubItems } from './style';

const info = [
  { name: 'Articles', href: '/learn' },
  { name: 'Graphics design', href: '/learn/tutorial/graphic-design' },
  { name: 'After effect templates', href: '/learn/tutorial/after-effects-templates' },
];

const Learn = () => {
  return (
    <Stack alignSelf="start" spacing={1}>
      <Typography variant='h6' fontWeight={600}>
				Learn
      </Typography>
      {info.map((item, index) => (
        <Fragment key={index}>
          <Link href={item.href}>
            <SubItems fontSize={'0.85rem'} fontWeight={400}>
              {item.name}
            </SubItems>
          </Link>
        </Fragment>
      ))}
    </Stack>
  );
};

export default Learn;
