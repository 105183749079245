'use client';
import Image from 'next/image';

import { Box, Button, Stack, Typography } from '@mui/material';

import { DialogDrawer } from 'libs/components/dialog-drawer';
import { FlexBox } from 'libs/components/flex-box';
import { themeColors } from 'theme/theme-colors';

const DownloadPopup = () => {
  return (
    <DialogDrawer
      dialogOpen
      toggleDialog={() => {}}
      wrapperStyle={{ height: '100%', maxWidth: '80vw', minHeight: '45dvh' }}
    >
      <Box
        sx={{
          borderRadius: '1rem',
          border: '1px solid #A29BFF',
          background: '#FFFFFF',
          padding: '5%',
          paddingRight: '0%',
        }}
        width={'100%'}
      >
        <Stack alignItems={'center'} spacing={1} padding={'2%'}>
          <Stack spacing={2}>
            <FlexBox
              flexDirection={{
                sm: 'row',
                xs: 'column-reverse',
              }}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Stack
                sx={{
                  overflowWrap: 'break-word',
                  textAlign: { xs: 'center', sm: 'left' },
                  position: 'relative',
                  zIndex: 3,
                }}
                spacing={2}
              >
                <Typography
                  fontSize={'1.25rem'}
                  color={themeColors.grey[600]}
                  fontStyle={'normal'}
                  fontWeight={'600'}
                  lineHeight={'noraml'}
                  textTransform={'capitalize'}
                >
									you reached your daily limit Subscribe to keep downloading
                </Typography>

                <Stack spacing={2} width={'100%'}>
                  <Typography
                    fontSize={'1.25rem'}
                    fontStyle={'normal'}
                    fontWeight={'500'}
                    lineHeight={'normal'}
                    textTransform={'capitalize'}
                  >
										Go premium and enjoy the benefits!
                  </Typography>
                  <Typography
                    fontSize={'0.875rem'}
                    fontStyle={'normal'}
                    fontWeight={500}
                    lineHeight={'normal'}
                  >
										As a free user, your download limit of 1 downloads/day. Get
										a premium subscription from ₹1250/year and get access to
										over +3,57,00 premium resources
                  </Typography>

                  <FlexBox justifyContent={'center'} gap={'2rem'}>
                    <Button
                      sx={{
                        borderRadius: '0.375rem',
                        border: '1px solid #6E5BE4',
                        background: '#FFF',
                        color: themeColors.info[800],
                      }}
                    >
											Wait 24hrs
                    </Button>
                    <Button
                      sx={{
                        borderRadius: '0.375rem',
                        background: themeColors.info[800],
                        color: '#fff',
                      }}
                    >
											Subsribe
                    </Button>
                  </FlexBox>
                </Stack>
              </Stack>
              <Box>
                <Image
                  src={`${process.env.CONTENT_BASE_URL}/static/next/public/assets/images/Popup-Images/45 Surprised-above-Resize 2.svg`}
                  alt="Image"
                  width={300}
                  height={200}
                  style={{
                    transform: 'translateX(-10%)',
                    position: 'relative',
                    zIndex: 1,
                  }}
                />
              </Box>
            </FlexBox>
          </Stack>
        </Stack>
      </Box>
    </DialogDrawer>
  );
};

export default DownloadPopup;
