import Image from 'next/image';

import { Box } from '@mui/material';

import { FlexBox } from '../Flex/flex-box';

interface LogoProps {
	direction?: string;
}

const Logo = ({ direction = 'row' }: LogoProps) => {
  return (
    <FlexBox
      alignItems="center"
      gap={{ md: 1, sm: 0 }}
      sx={{ flexDirection: direction }}
    >
      <span>
        <Image
          src={`${process.env.CONTENT_BASE_URL}/static/next/public/assets/images/LOGO_02_00000 1.png`}
          alt={'LogoImg'}
          width={50}
          height={50}
        />
      </span>
      <Box>
        <span
          style={{
            color: 'black',
            fontWeight: 'bold',
          }}
        >
					Design
        </span>
        <span
          style={{
            color: 'black',
          }}
        >
					Template
        </span>
      </Box>
    </FlexBox>
  );
};

export default Logo;
