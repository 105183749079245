'use client';

import { createContext, PropsWithChildren, useMemo, useReducer } from 'react';

type InitialState = { user: userDetails };

export type userDetails = {
  email: string;
  role: string;
  id: string;
  isVerified: boolean;
};

type UserActionType =
  | {
      type: 'LOGIN';
      payload: userDetails;
    }
  | {
      type: 'LOGOUT';
    };

const INITIAL_STATE = { user: {} };

// ==============================================================
interface ContextProps {
  state: InitialState;
  dispatch: (_args: UserActionType) => void;
}
// ==============================================================

export const LoginContext = createContext<ContextProps>({} as ContextProps);

const reducer = (state: InitialState, action: UserActionType) => {
  switch (action.type) {
    case 'LOGIN':
      return { user: action.payload };
    case 'LOGOUT':
      return INITIAL_STATE;

    default: {
      return state;
    }
  }
};

const LoginContextProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer<any>(reducer, INITIAL_STATE);

  const user = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <LoginContext.Provider value={user as any}>
      {children}
    </LoginContext.Provider>
  );
};

export default LoginContextProvider;
