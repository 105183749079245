import { Fragment } from 'react';

import Link from 'next/link';

import { Typography } from '@mui/material';

import { FlexBox } from 'libs/components/Flex/flex-box';
import { SubItems } from './style';

export default function HomeSection () {
  return (
    <Fragment>
      <FlexBox flexDirection={'column'} alignSelf="start" gap={1}>
        <Typography variant='h6' sx={{ background : 'linear-gradient(90deg, #623BE0 5.14%, #E52775 99.7%)', backgroundClip:'text', color:'transparent'}}>Designtemplate</Typography>
        {options.map((item) => (
          <Link href={item.url} key={item.name}>
            <SubItems fontSize={'0.85rem'} fontWeight={400}>
              {item.name}
            </SubItems>
          </Link>
        ))}
      </FlexBox>
    </Fragment>
  );
}

const options = [
  {
    name: 'Home',
    url:'/'
  },
  {
    name: 'Pricing',
    url:'/pricing'
  },
  {
    name: 'Content on Demand',
    url:'https://forms.gle/qeKYNfdbaEHMtoQV8'
  },
];
