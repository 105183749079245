'use client';

import { ReactNode } from 'react';

import merge from 'lodash/merge';

import CssBaseline from '@mui/material/CssBaseline';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';

import NextAppDirEmotionCacheProvider from './emotion-cache';
import customThemeOptions from './theme-options';

const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const themeOptions = customThemeOptions();

  const mergedThemeOptions = merge({}, { ...themeOptions });

  let theme = createTheme(mergedThemeOptions as unknown as any);

  theme = responsiveFontSizes(theme);

  theme.shadows[1] = '0px 1px 3px rgba(3, 0, 71, 0.09)';
  theme.shadows[2] = '0px 4px 16px rgba(43, 52, 69, 0.1)';
  theme.shadows[3] = '0px 8px 45px rgba(3, 0, 71, 0.09)';
  theme.shadows[4] = '0px 0px 28px rgba(3, 0, 71, 0.01)';

  return (
    <NextAppDirEmotionCacheProvider options={{ key: 'css' }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </NextAppDirEmotionCacheProvider>
  );
};

export default ThemeProvider;
