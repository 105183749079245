import { Fragment } from 'react';


import { Stack, Typography } from '@mui/material';

import { SubItems } from './style';

const info = [
  {
    name: 'Learn',
    href: '/learn',
  },
  { name: 'About Us', href: '/about-us' },
  { name: 'Terms of uses', href: '/terms-of-use' },
  { name: 'Privacy Policy', href: '/privacy-policy' },
  { name: 'Refund Policy', href: '/refund-policy' },
  {
    name: 'Shipping and Delivery Policy',
    href: '/shipping-and-delivery-policy',
  },
];

const MoreInfo = () => {
  return (
    <Stack alignSelf="start" spacing={1}>
      <Typography variant='h6' fontWeight={600}>
				Company
      </Typography>
      {info.map((item, index) => (
        <Fragment key={index}>
          <a href={item.href}>
            <SubItems fontSize={'0.85rem'} fontWeight={400}>
              {item.name}
            </SubItems>
          </a>
        </Fragment>
      ))}
    </Stack>
  );
};

export default MoreInfo;
