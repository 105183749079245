import useDialougeToggler from 'hooks/useDialougeToggler';
import DownloadPopup from 'libs/components/Popups/DownloadingPopup';
import FavouriteTemplate from 'libs/components/Popups/favourite-template';
import Onedownloadleft from 'libs/components/Popups/onedownloadleft';
import PremiumSubscriptionPopup from 'libs/components/Popups/premium-subscriptio-popup';
import PremiumSubscription from 'libs/components/Popups/premium-subscription';
import ProUserPopup from 'libs/components/Popups/pro-user-popup';
import ShowPlans from 'libs/components/Popups/showplans';
import UserAccountCreation from 'libs/components/Popups/user-account-creation';
import UserSubscriptionCreation from 'libs/components/Popups/user-subscription-creation';
import { DialogDrawer } from 'libs/components/dialog-drawer';
import LoginForm from 'libs/components/login-form';
import RegistrationForm from 'libs/components/registration-form';
import VerificationForm from 'libs/components/verification-form';

export const DialougeHandler = () => {
  const { state } = useDialougeToggler();
  // Define the mapper object
  const componentMapper: any = {
    RegistrationForm: <RegistrationForm />,
    LoginForm: <LoginForm />,
    VerificationForm: <VerificationForm />,
    ForgotPasswordForm: <VerificationForm />,
    DownloadPopUp: <DownloadPopup />,
    UserAccountCreation: <UserAccountCreation />,
    UserSubscriptionCreation: <UserSubscriptionCreation />,
    FavouriteTemplate: <FavouriteTemplate />,
    Onedownloadleft: <Onedownloadleft />,
    ShowPlans: <ShowPlans />,
    ProUserPopup: <ProUserPopup />,
    PremiumSubscription: <PremiumSubscription />,
    PremiumSubscriptionPopup: <PremiumSubscriptionPopup />,
  };

  return (
    state?.toggle &&
		state.toggle.toggled && (
      <DialogDrawer dialogOpen={state.toggle.toggled} toggleDialog={() => {}}>
        {componentMapper[state.toggle.element]}
      </DialogDrawer>
    )
  );
};
