'use client';
import React, { useEffect } from 'react';

import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Cookies from 'js-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';

import LoginContextProvider from 'contexts/LoginContext';
import ToogleDialogProvider from 'contexts/ToogleDialog';
import ThemeProvider from 'theme/theme-provider';
import { DialougeHandler } from '../dialougeHandler';
import HomeLayout from '../layouts/home';

const queryClient = new QueryClient();

export default function LayoutProvider({
  children,
  planDetails,
}: {
  children: React.ReactNode;
  planDetails: {
    price: number;
    currency: string;
    symbol: string;
  };
}) {
  useEffect(() => {
    let fingerPrint: string | null | undefined;

    fingerPrint = localStorage.getItem('usr_token');
    fingerPrint = Cookies.get('usr_token');

    if (!fingerPrint) {
      const fpPromise = FingerprintJS.load();

      fpPromise.then((fp) => {
        fp.get().then((res) => {
          const visitorId = res.visitorId;

          localStorage.setItem('usr_token', visitorId);
          Cookies.set('usr_token', visitorId);
        });
      });
    }
  }, []);

  useEffect(() => {
    const handleError = (event: ErrorEvent) => {
      if (window?.clarity) {
        window.clarity(
          'set',
          'application-error',
          event?.error?.message || event?.message || ''
        );
      }
    };

    const handleUnhandledRejection = (event: PromiseRejectionEvent) => {
      if (window?.clarity) {
        window.clarity(
          'set',
          'unhandledrejection-application-error',
          event.reason?.message || 'Unhandled promise rejection'
        );
      }
    };

    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener(
        'unhandledrejection',
        handleUnhandledRejection
      );
    };
  }, []);

  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <ToogleDialogProvider>
          <LoginContextProvider>
            <HomeLayout planDetails={planDetails}>{children}</HomeLayout>
            <DialougeHandler />
          </LoginContextProvider>
        </ToogleDialogProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
