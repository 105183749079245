import { Fragment } from 'react';

import Link from 'next/link';

import { Stack, Typography } from '@mui/material';

import { SubItems } from './style';

const category = [
  {
    name: 'After Effects',
    href: '/after-effects-templates',
  },
  {
    name: 'Premiere Pro',
    href: '/premiere-pro-templates',
  },
  {
    name: 'Motion Graphics',
    href: '/motion-graphics',
  }
];

const Categories = () => {
  return (
    <Stack alignSelf="start" spacing={1}>
      <Typography variant='h6' fontWeight={600}>
				Categories
      </Typography>
      {category.map((item, index) => (
        <Fragment key={index}>
          <Link href={item.href}>
            <SubItems fontSize={'0.87rem'} fontWeight={400}>
              {item.name}
            </SubItems>
          </Link>
        </Fragment>
      ))}
    </Stack>
  );
};

export default Categories;
