import { useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';

import useDialougeToggler from 'hooks/useDialougeToggler';
import { FlexBox } from 'libs/components/flex-box';
import Freeuser from './freeuser';

const ShowPlans = () => {
  const { dispatch } = useDialougeToggler();

  const onChangeHandler = (event: any) => {
    if (event.target.value) {
      setLicensetype(event?.target?.value);
    }
  };

  const [licensetype, setLicensetype] = useState('');

  return (
    <Freeuser>
      <FormControl fullWidth>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
        >
          <FlexBox justifyContent={'space-between'} alignItems={'center'}>
            <FormControlLabel
              value="standard"
              control={<Radio />}
              label="Standard"
              onChange={onChangeHandler}
            />

            <Box>$60</Box>
          </FlexBox>
          <Stack paddingLeft={'10%'}>
            {licensetype === 'standard' && (
              <ul style={{ listStyle: 'disc', paddingLeft: '2%' }}>
                <li>Get updates for 2 Month</li>
                <li>Commercial Usage up to 3 times</li>
                <li>24/7 Support For 2 Months</li>
              </ul>
            )}
          </Stack>
          <FlexBox justifyContent={'space-between'} alignItems={'center'}>
            <FormControlLabel
              value="extended"
              control={<Radio />}
              label="Extended"
              onChange={onChangeHandler}
            />
            <Box>$16</Box>
          </FlexBox>
          <Stack paddingLeft={'10%'}>
            {licensetype === 'extended' && (
              <ul style={{ listStyle: 'disc', paddingLeft: '2%' }}>
                <li>Get updates for 2 Month</li>
                <li>Commercial Usage up to 3 times</li>
                <li>24/7 Support For 2 Months</li>
              </ul>
            )}
          </Stack>
        </RadioGroup>

        <Button
          variant="outlined"
          fullWidth
          size="large"
          onClick={() =>
            dispatch({
              type: 'TOGGLE',
              payload: {
                toggled: true,
                element: 'UserAccountCreation',
                closeBtn: true,
              },
            })
          }
        >
					Buy Now
        </Button>
      </FormControl>
    </Freeuser>
  );
};

export default ShowPlans;
