import Link from 'next/link';

import { Box, Button, Stack } from '@mui/material';

import Freeuser from './freeuser';

const ProUserPopup = () => {
  return (
    <Freeuser user={'Pro'}>
      <Stack
        alignItems={'center'}
        justifyContent={'center'}
        spacing={2}
        width={'100%'}
      >
        <Button variant="outlined" size="large" fullWidth>
					Download
        </Button>
        <Box>Save 75% on our yearly subscription. <span>
          <Link href='/pricing'>
          Learn more
          </Link>
        </span>
        </Box>
      </Stack>
    </Freeuser>
  );
};

export default ProUserPopup;
