
import Divider from '@mui/material/Divider/Divider';

import { FlexBox } from 'libs/components/Flex/flex-box';
import Categories from './categories';
import HomeSection from './home-section';
import Learn from './learn-section';
import Links from './links';
import MoreInfo from './more-info';
import OfficeAdress from './office-address';
import { FooterBox, OuterBox } from './style';


const Footer = () => {
  return (
    <OuterBox>
      <FooterBox>
        <FlexBox justifyContent={{xs:'space-between',md:'inherit'}} width={'80%'} gap={5} flexWrap={'wrap'}>
          <HomeSection/>
          <Categories />
          <MoreInfo />
          <Learn />
          <Links/>
        </FlexBox>
        <Divider sx={{ width: '100%', borderWidth: '0.05rem', borderColor: '#D5D5D5' }} />
        <OfficeAdress/>
      </FooterBox>
    </OuterBox>
  );
};

export default Footer;
