import Image from 'next/image';

import { Box, Button, Stack, Typography } from '@mui/material';

import { FlexBox } from 'libs/components/flex-box';
import { themeColors } from 'theme/theme-colors';
import Freeuser from './freeuser';

interface OnedownloadleftProps{
    DownloadBtn?:string
}

const Onedownloadleft = ({DownloadBtn}:OnedownloadleftProps) => {
  return (
    <Freeuser >
      <Stack width={'100%'} alignItems={'center'} justifyContent={'center'} spacing={2}>
        <FlexBox gap={3}>
          <Typography color={'#404040'}

            fontSize={'0.625rem'}
            fontStyle={'normal'}
            fontWeight={500}
            lineHeight={'normal'}>


            <span style={{width:'8vw'}}>
              <Image  src={'assets/images/popup-images/Vector (5).svg'} alt="image" width={17} height={10}/>
            </span>You have one free download left on this plan.
          </Typography>
          <Stack>
            <Box>$16</Box>
            <Box color={'#404040'}>Free</Box>
          </Stack>
        </FlexBox>

        {DownloadBtn ? <Button variant="outlined" fullWidth size="large">
    Download
        </Button> : <FlexBox justifyContent={'space-between'} gap={'2rem'}>
          <Button size="large" sx={{
            borderRadius: '0.375rem',
            border: '1px solid #6E5BE4',
            background: '#FFF',
            color: themeColors.info[800],
          }}>
                                        Wait 24hrs
          </Button>
          <Button size="large" sx={{
            borderRadius: '0.375rem',
            background: themeColors.info[800],
            color: '#fff'
          }}>
                                        Buy Now
          </Button>
        </FlexBox>


        }
        <Box>
    Get this template and thousands more of our hand picked templates, with subscription. Get now!
        </Box>
      </Stack>
    </Freeuser>
  );
};

export default Onedownloadleft;
