'use client';
import React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FlexBoxCentered } from 'libs/components/Flex/flex-box';

export const ImageButton = styled(Box)(({ theme }) => ({
  width: '4.24538rem',
  height: '0.39975rem',
  borderRadius: '1.625rem',
  background: '#9A9393',
  [theme.breakpoints.down('sm')]: { width: '2rem', padding: '2%' },

  ':hover': {
    cursor: 'pointer',
    background: '#FFF',
  },
  ':active': {
    cursor: 'pointer',
    background: '#FFF',
  },
}));

interface PopupLayoutProps {
	children: React.ReactNode;
	height?: string;
	active: string;
}

export default function PopupLayout ({ children }: PopupLayoutProps) {
  return (
    <>
      <FlexBoxCentered alignItems={'center'} justifyContent={'center'}>
        <Box
          display={{
            sm: 'flex',
          }}
        >
          {/* <Box height={"100%"}>
						{!isMobile && (
							<ImageCarasoulDiv>
								<Image
									src="${process.env.CONTENT_BASE_URL}/static/next/public/assets/images/login.png"
									alt="hero"
									width={350}
									height={550}
									style={{ display: "block", maxWidth: "100%" }}
								/>
								<div
									style={{
										position: "absolute",
										color: "white",
										bottom: "0%",
										paddingBottom: "8rem",
									}}
								>
									<Stack spacing={2} flexWrap={"wrap"} paddingLeft={"5%"}>
										<Typography
											fontFamily={typography.fontFamily}
											fontSize={"1.125rem"}
											fontWeight={500}
											lineHeight="normal"
											color="#FFF"
											sx={{
												textAlign: {
													sm: "center",
													md: "start",
												},
											}}
										>
											Discover the best !
										</Typography>
										<Typography
											fontFamily={typography.fontFamily}
											fontSize={"1.125rem"}
											fontWeight={500}
											lineHeight={"normal"}
											color={"#FFF"}
											sx={{
												textAlign: {
													sm: "center",
													md: "start",
												},
											}}
										>
											A smart choice, The best collections of illustration for
											designers
										</Typography>
									</Stack>
									<FlexBoxCentered
										gap={2}
										sx={{ transform: "translateY(500%)" }}
										justifyContent={"center"}
									>
										<ImageButton
											sx={{
												backgroundColor:
													active === "registration" ? "#FFF" : "#9A9393",
											}}
										/>

										<ImageButton
											sx={{
												backgroundColor:
													active === "verification" ? "#FFF" : "#9A9393",
											}}
										/>
										<ImageButton
											sx={{
												backgroundColor:
													active === "login" ? "#FFF" : "#9A9393",
											}}
										/>
									</FlexBoxCentered>
								</div>
							</ImageCarasoulDiv>
						)}
					</Box> */}
          <Box
            paddingY={'3%'}
            width={{ md: '40rem' }}
            display={'flex'}
            justifyContent={{ md: 'center', xs: 'start' }}
            style={{ overflow: 'scroll' }}
            sx={{
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {children}
          </Box>
        </Box>
      </FlexBoxCentered>
    </>
  );
}
