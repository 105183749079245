import { Box, styled } from '@mui/material';

export const StyledOfferContainer = styled('div')(({ theme }) => ({
  background: 'linear-gradient(90deg, #FF006B 0%, #4729FF 100%)',
  height: '60px',
  [theme.breakpoints.down('md')]: {
    height: '50px',
  },
}));

export const StyledOfferText = styled('p')(({ theme }) => ({
  color: '#FFF',
  textAlign: 'center',
  fontSize: 18,
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    fontSize: 14,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}));

export const StyledText = styled('p')(({ theme }) => ({
  color: '#FFF',
  textAlign: 'center',
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  cursor: 'pointer',

  [theme.breakpoints.down('md')]: {
    fontSize: 12,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 10,
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '6px',
  height: '60px',
  cursor: 'pointer',

  [theme.breakpoints.down('md')]: {
    height: '50px',
    gap: '4px',
  },
}));
