'use client';
import React from 'react';

import Image from 'next/image';

import { Box, Stack, Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';

import { typography } from 'theme/typography';
import { DialogDrawer } from '../dialog-drawer';

const UserAccountCreation = () => {
  const StyledBox = styled(Box)(() => ({
    width: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    ':before': {
      content: '""',
      zIndex: 5,
      width: '100vw',
      height: '100dvh',
      position: 'absolute',

      top: 0,
      left: 0,
      background: `url(${process.env.CONTENT_BASE_URL}/static/next/public/assets/Images/AccountCreationImg.png)`,
    },
  }));

  return (
    <DialogDrawer
      dialogOpen
      toggleDialog={() => {}}
      wrapperStyle={{ height: '100%', width: '100%' }}
    >
      <StyledBox
        minHeight={'70dvh'}
        padding={'2%'}
        minWidth={'40vw'}
        sx={{ height: { xs: '100dvh', sm: 'auto' } }}
      >
        <Stack alignItems={'center'} zIndex={99} justifyContent={'center'}>
          <Image
            src={'assets/Images/tickImg.svg'}
            alt="Success"
            width={100}
            height={100}
          />

          <Typography
            variant="h5"
            fontFamily={typography.fontFamily}
            fontSize={{ md: '1.875rem', xs: '1.1rem' }}
            fontWeight={500}
          >
						Account created successfully
          </Typography>
        </Stack>
      </StyledBox>
    </DialogDrawer>
  );
};

export default UserAccountCreation;
